import React from "react"
import { graphql } from "gatsby"
import { Partners as PageQuery, Link, Meta, PartnersSEO, Menu, SiteSettings, SiteBanner } from 'src/queries'
import Layout from 'src/components/layout'
import Slices from 'src/components/slices'
import {getPageData} from 'src/utilities/data'
import SEO from 'src/components/seo'

const Partners = getPageData(({pageData, menuData, siteBannerData, siteSettings, pageContext}) => (
  <Layout pageContext={pageContext} menuData={menuData} siteBannerData={siteBannerData} siteSettings={siteSettings} navShadow>
    <SEO {...pageData} lang={pageContext.lang} />
    <Slices data={pageData.body}/>
  </Layout>
))

Partners.fragments = [PageQuery, Link, Meta, PartnersSEO, Menu, SiteSettings, SiteBanner]
export default Partners
export const query = graphql`
  query ($lang: String) {
    prismic {
      allPartnerss(lang: $lang) { ...Partners }
      allMenus(lang: $lang) { ...Menu }
      allSite_settingss(lang: $lang) { ...SiteSettings }
      allSite_banners(lang: $lang) { ...SiteBanner }
    }
  }
`